<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="license"
      label="车牌号"
      placeholder="请输入车牌号"
      maxlength="7"
    />

    <van-field
      v-model="alias"
      label="大号"
      placeholder="请输入大号"
    />

    <van-field
      v-model="driverName"
      label="司机"
      placeholder="请输入司机"
    />

    <van-field
      name="radio"
      label="是否短驳"
    >
      <template #input>
        <van-radio-group
          v-model="radio"
          direction="horizontal"
        >
          <van-radio
            name="1"
            shape="square"
          >是</van-radio>
          <van-radio
            name="2"
            shape="square"
          >否</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field
      v-if="disabled"
      v-model="dumpSite"
      label="卸点名称"
      placeholder="请输入卸点名称"
    />

    <van-field
      v-else-if="!disabled"
      readonly
      clickable
      name="dumpSiteId"
      v-model="dumpSite"
      label="卸点名称"
      placeholder="点击选择卸点名称"
      is-link
      arrow-direction="down"
      :required="visible ? false : true"
      :rules="[{ required: visible ? false : true, message: '卸点名称不能为空' }]"
      @click="showDumpSite = true"
    />
    <van-popup
      v-model:show="showDumpSite"
      position="bottom"
    >
      <Picker
        :columns="columnsDumpSite"
        v-on:change="value => columnActiveDump = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmDumpSite"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      readonly
      clickable
      name="constructionSectionId"
      v-model="constructionSection"
      label="施工段"
      placeholder="点击选择施工段"
      is-link
      arrow-direction="down"
      required
      :rules="[{ required: true, message: '施工段不能为空' }]"
      @click="showConstructionSection = true"
    />
    <van-popup
      v-model:show="showConstructionSection"
      position="bottom"
    >
      <Picker
        v-if="columnsDefaultActiveConstruction.length > 0"
        :columns="columnsDefaultActiveConstruction"
        :title="'最近选择'"
        v-on:change="value => columnActiveConstruction = value"
      />

      <Picker
        :columns="columnsConstructionSection"
        :title="'施工段'"
        v-on:change="value => columnActiveConstruction = value"
      />

      <van-cell>
        <div
          class="add"
          @click="addConstruction"
        >
          <van-icon name="plus" /> 新增
        </div>
      </van-cell>

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmConstructionSection"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      v-if="visible"
      readonly
      clickable
      name="vehicleCompanyId"
      v-model="company"
      label="外包公司"
      placeholder="点击选择公司名称"
      required
      is-link
      arrow-direction="down"
      :rules="[{ required: true, message: '公司名称不能为空' }]"
      @click="showCompany = true"
    />
    <van-popup
      v-model:show="showCompany"
      position="bottom"
    >
      <Picker
        :columns="columnsCompany"
        :column="2"
        v-on:change="value => columnActiveCompany = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmCompany"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <div style="margin: 16px;">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
      >
        提交
      </van-button>
    </div>

    <van-dialog
      v-model:show="show"
      title="新增施工段"
      show-cancel-button
      @cancel="name = ''"
      @confirm="confirmAdd"
    >
      <van-field
        v-model="name"
        label="名称"
        placeholder="请输入名称"
        autofocus
        required
      />
    </van-dialog>
  </van-form>
</template>

<script>
import _ from "lodash";
import Picker from "../../../components/Picker.vue";

export default {
  props: [
    "data",
    "columnsDumpSite",
    "columnsConstructionSection",
    "columnsCompany",
    "visible"
  ],
  components: { Picker },
  data() {
    return {
      license: "",
      alias: "",
      driverName: "",
      radio: "2",
      disabled: false,
      readyLicense: false,
      readyAlias: false,

      dumpSite: "",
      dumpSiteId: undefined,
      showDumpSite: false,
      columnActiveDump: {},

      constructionSection: "",
      constructionSectionId: undefined,
      showConstructionSection: false,
      columnActiveConstruction: {},
      columnsDefaultActiveConstruction: [],
      show: false,
      name: "",

      company: "",
      vehicleCompanyId: undefined,
      showCompany: false,
      columnActiveCompany: {}
    };
  },
  mounted() {
    const { $watch, $emit } = this;
    // this.columnsDefaultActiveConstruction = [JSON.parse(localStorage.getItem("columnActiveConstruction"))] || [];

    $watch("data", newValue => {
      if (this.license == newValue.license) {
        this.readyLicense = true;
      }
      if (this.alias == newValue.alias) {
        this.readyAlias = true;
      }
      this.license = newValue.license;
      this.alias = newValue.alias;
      this.driverName = newValue.driverName;

      if (_.size(newValue) == 0) {
        this.dumpSite = "";
        this.dumpSiteId = undefined;
        this.constructionSection = "";
        this.constructionSectionId = undefined;
        this.company = "";
        this.vehicleCompanyId = undefined;
        this.radio = "2";
      }
    });

    $watch("columnsConstructionSection", () => {
      this.constructionSection = "";
      this.constructionSectionId = undefined;
      this.columnsDefaultActiveConstruction = [];
    }, { deep: true });

    $watch("license", newValue => {
      const { readyLicense } = this;
      if (newValue && newValue.length == 7 && !readyLicense) {
        $emit("handleVehicle", { license: newValue });
      }else{
        this.readyLicense = false;
      }
    });

    $watch("alias", newValue => {
      if (newValue) {
        setTimeout(() => {
          const { alias, readyAlias } = this;
          if (newValue == alias) {
            $emit("handleVehicle", { alias: newValue });
          }
        }, 2000);
      }
    });

    $watch("radio", newValue => {
      if (newValue == 1) {
        let columnActiveProject = JSON.parse(localStorage.getItem("columnActiveProject")) || {};
        this.disabled = true;
        this.dumpSite = columnActiveProject.name;
        this.dumpSiteId = columnActiveProject.id;
      } else if (newValue == 2) {
        this.disabled = false;
        this.dumpSite = "";
        this.dumpSiteId = undefined;
      }
    });
  },
  methods: {
    onConfirmDumpSite() {
      const {
        columnActiveDump: { id, text }
      } = this;
      this.dumpSite = text;
      this.dumpSiteId = id;
      this.showDumpSite = false;
    },
    onConfirmConstructionSection() {
      const {
        columnActiveConstruction: { id, text }
      } = this;
      localStorage.columnActiveConstruction = JSON.stringify({
        id,
        text,
        name: text
      });
      this.columnsDefaultActiveConstruction = [{
        id,
        text,
        name: text
      }];
      this.constructionSection = text;
      this.constructionSectionId = id;
      this.showConstructionSection = false;
    },
    addConstruction() {
      this.show = true;
    },
    confirmAdd() {
      const { name } = this;
      if (name) {
        this.$emit("add", { name });
      }
    },
    onConfirmCompany() {
      const {
        columnActiveCompany: { id, text }
      } = this;
      this.company = text;
      this.vehicleCompanyId = id;
      this.showCompany = false;
    },
    onSubmit() {
      const {
        license,
        alias,
        driverName,
        dumpSiteId,
        constructionSectionId,
        vehicleCompanyId
      } = this;

      this.$emit("onsubmit", {
        license,
        alias,
        driverName,
        dumpSiteId,
        constructionSectionId,
        vehicleCompanyId
      });
    }
  }
};
</script>
