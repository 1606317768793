import { createStore } from 'vuex'

// 测试 actions 发起请求

export default createStore({
  state: {
    loading: false,
    project: [],
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload.loading
    },
    setProject(state, payload) {
      console.log(this)
      console.log(state, payload)
    }
  },
  actions: {
    aa({ commit }) {
      commit('setProject')
    }
  },
  modules: {
  },
  getters: {
    // loading: (state) => state.loading,
  }
})
