import { createRouter, createWebHistory } from 'vue-router'
import { setCookie, getQueryArgs, decrypt } from "@/utils/util.js";
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/oil',
    name: 'Oil',
    component: () => import('../views/oil/Oil.vue'),
    meta: {
      title: '加油上报'
    }
  },
  {
    path: '/oil/edit',
    name: 'OilEdit',
    component: () => import('../views/oil/OilEdit.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/work/Work.vue'),
    meta: {
      title: '装车统计'
    }
  },
  {
    path: '/work/edit',
    name: 'Worksite',
    component: () => import('../views/work/WorkSite.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/dump',
    name: 'Dump',
    component: () => import('../views/dump/Dump.vue'),
    meta: {
      title: '卸车统计'
    }
  },
  {
    path: '/dump/edit',
    name: 'Dumpsite',
    component: () => import('../views/dump/DumpSite.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/complate',
    name: 'Complate',
    component: () => import('../views/result/Complate.vue'),
    meta: {
      title: '结果页'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
  } // 用于生产测试
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if (to.query.title == 'driver' || to.query.title == 'user') {
      document.title = '加油上报';
    } else if (to.query.title == 'admin') {
      document.title = '装车统计';
    } else {
      document.title = to.meta.title;
    }
  }

  if (getQueryArgs('token')) {
    let token = getQueryArgs('token');
    let currentUser = JSON.parse(decrypt(token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'))));
    sessionStorage.companyId = currentUser.c;
    sessionStorage.token = token;
    setCookie('token', token, 24 * 60 * 1000);
  }

  if (!sessionStorage.token) {
    if (getQueryArgs('code')) {
      let _href = window.location.href;
      if (_href.match('admin')) {
        let _path = `${window.location.origin}?title=admin`;
        window.location.href = `${window.AUTH_API}auth/wechat/work/?code=${getQueryArgs('code')}&from=${encodeURIComponent(_path)}&state=${getQueryArgs('state')}`;
      } else if (_href.match('driver')) {
        let _path = `${window.location.origin}/oil?title=driver`;
        window.location.href = `${window.TRAIN_API}auth/wechat/work/?code=${getQueryArgs('code')}&from=${encodeURIComponent(_path)}&state=${getQueryArgs('state')}`;
      } else if (_href.match('user')) {
        let _path = `${window.location.origin}/oil?title=user`;
        window.location.href = `${window.AUTH_API}auth/wechat/work/?code=${getQueryArgs('code')}&from=${encodeURIComponent(_path)}&state=${getQueryArgs('state')}`;
      } else if (_href.match('edit')) {
        let _path = `${window.location.origin}${window.location.pathname}?id=${getQueryArgs('id')}`;
        window.location.href = `${window.AUTH_API}auth/wechat/work/?code=${getQueryArgs('code')}&from=${encodeURIComponent(_path)}&state=${getQueryArgs('state')}`;
      }
      next();
    } else if (getQueryArgs('token')) {
      let token = getQueryArgs('token');
      sessionStorage.token = token;
      setCookie('token', token, 24 * 60 * 1000);
      // let _path = `${window.location.origin}${window.location.pathname}`;
      // next({ path: _path });
      next();
    }
  }
  next();
});

export default router
