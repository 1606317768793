<template>
  <div class="work">
    <div class="upload-info" v-if="dumpPhoto">
      <div class="title">添加车头照片</div>
      <Upload v-on:onupload="handleSend" />
      <div class="tips">
        <p
          class="error"
          v-if="externalVisible"
        >
          <span>该车非我司车辆</span>
        </p>
        <p
          class="success"
          v-if="companyVisible"
        ><span>该车为我司车辆</span></p>
        <p
          class="error"
          v-if="visible"
        >
          <span>无法识别车辆信息</span>
          <span>请重新拍照并上传</span>
        </p>
      </div>
    </div>

    <Form
      :data="data"
      :columnsDumpSite="columnsDumpSite"
      v-on:handleVehicle="handleVehicle"
      v-on:onsubmit="onSubmit"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { Toast, Notify } from "vant";
import Upload from "@/components/Upload.vue";
import Form from "./components/Form.vue";

export default {
  name: "Dump",
  props: [
    'projectActive'
  ],
  components: { Upload, Form },
  data() {
    return {
      data: {},
      params: {},
      visible: false,
      companyVisible: false,
      externalVisible: false,
      dumpPhoto: true,
    };
  },
  mounted() {
    const { $watch, projectActive } = this;
    this.dumpPhoto = projectActive.dumpPhoto;

    $watch('projectActive', newValue => {
      this.data = {};
      this.companyVisible = false;
      this.externalVisible = false;
      this.visible = false;
      this.dumpPhoto = newValue.dumpPhoto;
    })
  },
  methods: {
    handleSend({ url, lng, lat }) {
      this.params = {
        endImg: url,
        endLongitude: lng,
        endLatitude: lat,
      }

      this.$request.updateImg({ url, type: 2 }, response => {
        if (response.status === 200) {
          const result = response.data;
          if (result.retCode === 200) {
            const { license, alias } = result;
            this.data = { license, alias };
            this.visible = false;

            this.loadVehicle({ license });
          } else {
            this.data = {};
            this.params = {};
            this.visible = true;
          }
        }
      },
      {
        Authorization: true
      });
    },
    handleVehicle({ license, alias }) {
      this.loadVehicle({ license, alias });
    },
    
    loadVehicle({ license, alias }) {
      const initVehicle = result => {
        if (result) {
          const { id, license, alias } = result;
          this.data = { license, alias };
          this.companyVisible = true;
          this.externalVisible = false;

          this.$request.queryDriver({ id: result.id }, response =>{
            if (response.status == 200) {
              if (response.data.driver) {
                const { id, driverName, userId } = response.data.driver;
                this.params.driverId = id;
                this.params.driverName = driverName;
                this.params.driverUserId = userId;
                this.data = { ...this.data, driverName };
              }
            }
          });
        } else {
          this.data = { license, alias };
          this.params.driverId = '';
          this.params.driverName = '';
          this.params.driverUserId = '';
          this.companyVisible = false;
          this.externalVisible = true;
        }
      }

      if (!sessionStorage.vehicle) {
        this.$request.queryVehicle({}, response => {
          if (response.status === 200) {
            const result = response.data;
            sessionStorage.vehicle = JSON.stringify(result);

            let data = result.filter(item => item.license == license || item.alias == alias);
            initVehicle(data[0]);
          }
        })
      }else{
        const result = JSON.parse(sessionStorage.getItem('vehicle')) || [];
        let data = result.filter(item => item.license == license || item.alias == alias);
        initVehicle(data[0]);
      }
    },
    onSubmit(values) {
      const { $store, projectActive: { dumpSiteId }, params, dumpPhoto } = this;
      values = { ...values, ...params, dumpSiteId };

      if (dumpPhoto && !values.endImg) {
        Toast("请上传图片");
        return;
      }

      if (!values.license && !values.alias) {
        Toast("车牌号、大号需选填一项");
        return;
      }

      _.forIn(values, (v, k) => {
        if (!v) delete values[k];
      });

      if ($store.state.loading) return;
      $store.commit("setLoading", { loading: true });
      
      this.$request.addDumpRecord({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit("setLoading", { loading: false });

          if (result.code === 1) {
            Notify({ type: "success", message: "保存成功" });
            this.data = {};
            this.params = {};
            this.$router.replace({
              path: "/complate",
              query: { source: "dump" }
            });
          } else {
            Notify({ type: "warning", message: result.msg });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.work {
  .upload-info {
    background: #fff;
    padding: 12px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
    }

    .tips {
      width: 50%;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      float: right;

      .error {
        color: $red;
      }

      .success {
        color: $blue;
      }

      span {
        display: block;
        font-size: 16px;
      }
    }
  }
}
</style>
