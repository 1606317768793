// get => query
// post => add/update
// put => update

const ApiCMS = window.CMS;
const ApiOIL = window.OIL_API;
const ApiM = window.M_API;
const ApiAUTH = window.AUTH_API;
const ApiIMG = window.IMG_API;
const ApiWX = window.WX_API;

const api = {
  gettoken: ApiWX + 'cgi-bin/gettoken',

  authRefresh: ApiAUTH + 'auth/refresh', // token刷新
  updateCommonUploadToken: ApiCMS + 'common/upload_token', // 上传图片
  queryDrivers: ApiCMS + 'driver/option', //  司机
  updateImg: ApiIMG, // 识别邮票
  queryProject: ApiOIL + 'project',   // 通过车牌找公司、大号（别名）
  queryVehicle: ApiM + 'vehicle/option',   // 通过车牌找公司、大号（别名）
  queryDriver: ApiM + 'vehicle',  // 司机信息
  queryOilTicket: ApiOIL + 'oil_ticket',  // 邮票
  queryOilTicketView: ApiOIL + 'oil_ticket/detail',
  putOilTicket: ApiOIL + 'oil_ticket',  // 新增、修改
  queryConstructionSection: ApiOIL + 'construction_section',  // 施工段
  putConstructionSection: ApiOIL + 'construction_section',  // 新增、修改

  queryExternalCompany: ApiOIL + 'external_company',  // 外包公司
  putExternalCompany: ApiOIL + 'external_company',  // 新增、修改
  queryWaybillDetail: ApiOIL + 'waybill/detail',
  addWaybill: ApiOIL + 'waybill/load', // 装车新增
  addDumpRecord: ApiOIL + 'dump_record', // 卸车新增
  updateWaybill: ApiOIL + 'waybill', // 装卸车修改
}

const getAry = [
  'gettoken',
  'authRefresh',
  'queryDrivers',
  'updateCommonUploadToken',
  'queryProject',
  'queryVehicle',
  'queryDriver',
  'queryOilTicket',
  'queryOilTicketView',
  'queryConstructionSection',
  'queryExternalCompany',
  'queryWaybillDetail',
];
const postAry = [
  'updateImg',
  'addWaybill',
];
const putAry = [
  'putOilTicket',
  'putConstructionSection',
  'putExternalCompany',
  'updateWaybill',
  'addDumpRecord',
];

export { api, getAry, postAry, putAry }