<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="license"
      label="车牌号"
      placeholder="请输入车牌号"
    />

    <van-field
      v-model="alias"
      label="大号"
      placeholder="请输入大号"
    />

    <van-field
      v-model="driverName"
      label="司机"
      placeholder="请输入司机"
    />

    <van-field name="radio" label="是否短驳">
      <template #input>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio name="1" shape="square">是</van-radio>
          <van-radio name="2" shape="square">否</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    
    <van-field name="vehicleType" label="车辆类型">
      <template #input>
        <van-radio-group v-model="vehicleType" direction="horizontal">
          <van-radio name="1" shape="square">小车</van-radio>
          <van-radio name="2" shape="square">大车</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field v-model="weightInit" type="number" label="重车" />

    <van-field v-model="weight" type="number" label="净重" disabled />

    <div style="margin: 16px;">
      <van-button :loading="$store.state.loading" round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>


<script>
import _ from "lodash";
// vehicleType 2 => 20T    1 => 18T

export default {
  props: [
    'data'
  ],
  data() {
    return {
      license: '',
      alias: '',
      driverName: '',
      loadSiteId: undefined,
      radio: '2',
      vehicleType: undefined,
      weightInit: undefined,
      weight: undefined,
      weightValue: { 1: 18, 2: 20 },
      readyLicense: false,
    };
  },
  mounted() {
    const { $watch, $emit } = this;

    $watch('data', (newValue) => {
      console.log(newValue)
      if (this.license == newValue.license) {
        this.readyLicense = true;
      }
      this.license = newValue.license;
      this.alias = newValue.alias;
      this.driverName = newValue.driverName;

      if (_.size(newValue) == 0) {
        this.radio = "2";
      }
    })

    $watch("license", newValue => {
      const { readyLicense } = this;
      if (newValue && newValue.length == 7 && !readyLicense) {
        $emit("handleVehicle", { license: newValue });
      }else{
        this.readyLicense = false;
      }
    });

    $watch("alias", newValue => {
      if (newValue) {
        setTimeout(() => {
          const { alias, readyAlias } = this;
          if (newValue == alias) {
            console.log('alias')
            $emit("handleVehicle", { alias: newValue });
          }
        }, 2000);
      }
    });

    $watch("radio", newValue => {
      if (newValue == 1) {
        let columnActiveProject = JSON.parse(localStorage.getItem("columnActiveProject")) || {};
        this.loadSiteId = columnActiveProject.id;
      } else if (newValue == 2) {
        this.loadSiteId = undefined;
      }
    });

    $watch('vehicleType', newValue => {
      const { weightInit, weightValue } = this;
      if (newValue && weightInit) this.weight = weightInit - weightValue[newValue];
    })

    $watch('weightInit', newValue => {
      const { vehicleType, weightValue } = this;
      let value = parseInt(newValue*10);
      this.weightInit = value > 700 ? 70 : value/10;

      if (vehicleType) this.weight = this.weightInit - weightValue[vehicleType];
    })
  },
  methods: {
    onSubmit() {
      const { license, alias, driverName, loadSiteId, vehicleType, weight } = this;
      
      this.$emit('onsubmit', {
        license,
        alias,
        driverName,
        loadSiteId,
        vehicleType,
        weight: weight*100,
      });
    },
  },
}
</script>
