<template>
  <div class="home">
    <Menu
      :columnsProjectWork="columnsProjectWork"
      :columnsProjectDump="columnsProjectDump"
      v-on:handleProject="handleProject"
    />

    <div>
      <Work v-if="workShow" :projectActive="projectActive" :columnsProjectDump="columnsProjectDump" />
      <Dump v-if="dumpShow" :projectActive="projectActive" />
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
// import { decrypt } from "@/utils/util.js";
import { Toast } from 'vant';
import Menu from "./home/components/WorkMenu";
import Work from "./work/Work";
import Dump from "./dump/Dump";

export default {
  name: 'Home',
  components: { Menu, Work, Dump },
  data() {
    return {
      currentUser: {},
      columnsProject: [],
      columnsProjectWork: [],
      columnsProjectDump: [],
      workShow: false,
      dumpShow: false,
      projectActive: {},
    }
  },
  mounted() {
    // 管理员 l < 3
    // 司机 l > 2
    // let token = sessionStorage.token;
    // if (token) {
    //   this.currentUser = JSON.parse(decrypt(token.substring(token.indexOf('.')+1, token.lastIndexOf('.'))));
    // }
    
    Toast.loading({
      message: '数据加载中',
      forbidClick: true,
      duration: 0,
    });
    let project = JSON.parse(sessionStorage.getItem('project')) || [];
    if (project.length > 0) {
      this.columnsProject = project;
      this.columnsProjectWork = JSON.parse(sessionStorage.getItem('projectWork')) || [];
      this.columnsProjectDump = JSON.parse(sessionStorage.getItem('projectDump')) || [];
      setTimeout(() => {
        Toast.clear();
      }, 1000);
    }
    else this.loadProject();
    // else this.$store.dispatch('aa')

    let columnActiveProject = JSON.parse(localStorage.getItem('columnActiveProject')) || {};
    const { id: projectId, dumpPhoto } = columnActiveProject;
    let tabActive = JSON.parse(localStorage.getItem('tabActive')) || {};

    if (tabActive && projectId) {
      this.projectActive = this.initMenuResponse({ projectId, active: tabActive.active, dumpPhoto });
    }
  },
  methods: {
    loadProject() {
      this.$request.queryProject({}, response => {
        if (response.status === 200) {
          const result = response.data;
          let columnsProjectWork = [], columnsProjectDump = [];
          result.map(item => {
            item.text = item.name;
            if (item.type == 3 || item.type == 2) {
              columnsProjectWork.push(item);
            }

            if (item.type == 1 || item.type == 2) {
              columnsProjectDump.push(item);
            }
          })
          sessionStorage.project = JSON.stringify(result);
          sessionStorage.projectWork = JSON.stringify(columnsProjectWork);
          sessionStorage.projectDump = JSON.stringify(columnsProjectDump);
          this.columnsProject = result;
          this.columnsProjectWork = columnsProjectWork;
          this.columnsProjectDump = columnsProjectDump;

          Toast.clear();
        }
      })
    },
    handleProject({ projectId, active, dumpPhoto }) {
      this.projectActive = this.initMenuResponse({ projectId, active, dumpPhoto });
    },
    initMenuResponse({ projectId, active, dumpPhoto }) {
      let params = {};

      if (active == 0) {
        this.workShow = true;
        this.dumpShow = false;
        params = { projectId, loadSiteId: projectId }
      } else if (active == 1) {
        this.workShow = false;
        this.dumpShow = true;
        params = { dumpSiteId: projectId, dumpPhoto }
      }

      return params;
    }
  }
}
</script>


<style lang="scss">
.home {
  height: 100%;

  &-oil {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-work {
    .router-link {
      margin-top: 40px;

      >div {
        float: left;
        display: inline-block;
        width: 50%;
        padding: 0 20px;
      }
    }
  }

  .home-oil-form {
    display: none;
  }
}

section {
  .van-picker-column {
    padding: 0 10px;
  }
  .near, .list-title {
    margin-bottom: 10px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-gap: 10px 10px;
    overflow: hidden;
    margin-bottom: 20px;
    
    > div {
      color: #333;
      border: 1px solid $borderColor;
      padding: 5px 10px;

      &.plus {
        border-style: dashed;
        text-align: center;
        color: $borderColor;
      }

      &.active {
        color: $blue;
        border-color: $blue;
      }
    }
  }
}
</style>
