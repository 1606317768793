<template>
  <van-uploader
    v-model="fileList"
    capture="camera"
    multiple
    :max-count="1"
    :max-size="5 * 1024 * 1024"
    @oversize="onOversize"
    :after-read="afterRead"
  >
    <template #preview-cover="{ file }"></template>
  </van-uploader>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
import {
  exif,
  renderImage,
  dataURLtoFile,
  compressImage,
  qiniuUpload
} from "@/utils/util.js";

export default {
  data() {
    return {
      file: {},
      fileList: [],
      image: "",
      point: {}
    };
  },
  methods: {
    onOversize() {
      Toast("文件大小不能超过 5M");
    },
    afterRead(file) {
      const _file = file.file;
      let _this = this;

      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0
      });

      exif(_file).then(res => {
        let lng = res.GPSLongitude,
          lat = res.GPSLatitude,
          orientation = res.Orientation;

        if (!lng && !lat) {
          if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
              position => {
                const point = position.coords;
                _this.point = {
                  lng: point.longitude,
                  lng: point.latitude
                };
              },
              err => {
                var geolocation = new BMap.Geolocation();
                geolocation.getCurrentPosition(function(r){
                  if(this.getStatus() == BMAP_STATUS_SUCCESS){
                    _this.point = r.point;
                  }      
                },{enableHighAccuracy: true})
              },
              {
                enableHighAccuracy: true
              }
            );
          }else{
            var geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(function(r){
              if(this.getStatus() == BMAP_STATUS_SUCCESS){
                _this.point = r.point;
              }      
            },{enableHighAccuracy: true})
          }
        }

        let reader = new FileReader();
        reader.onload = function(e) {
          let img = new Image();
          img.src = e.target.result;
          img.onload = function() {
            renderImage(img, orientation, base64 => {
              let options = {
                quality: 0.2,
                noCompressIfLarger: true
                // maxWidth: 800,
              };
              _this.update(dataURLtoFile(base64, _file.name), options);
            });
          };
        };
        reader.readAsDataURL(_file);
      });
    },
    update(file, options) {
      compressImage(file, options, res => {
        this.$request.updateCommonUploadToken(
          { bucketName: "t-resource" },
          response => {
            const { domain, token } = response.data;

            qiniuUpload(
              res.dist,
              `${moment().format("YYYYMMDDHHmmss")}/${file.name}`,
              token,
              ({ key }) => {
                this.$emit("onupload", { url: `${domain}/${key}`, ...this.point });
              }
            );
          }
        );
      });
    }
  }
};
</script>