import axios from 'axios';
import qs from 'qs';
import { Notify } from 'vant';
import { getCookie } from "./util";
import store from '../store';

axios.defaults.timeout = 300000;
// axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.response.use(
  response => {
    if (response.status != 200) {
      Notify({ type: 'warning', message: response.status });
    }
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response);
  },
  error => {
    if (error.response && error.response.data && error.response.data.code) {
      console.log(error.response.data);
      store.commit('setLoading', { loading: false });
      const result = error.response.data;
      Notify({ type: 'warning', message: result.msg });
    }
    return Promise.reject(error);
  }
)

const http = {
  get: (url, data, options) => {
    if (options && options.headers && options.headers['Content-Type']) {
      axios.defaults.headers.post['Content-Type'] = options.headers['Content-Type'];
    }

    // true  关闭token
    if (getCookie('token') && !axios.defaults.headers.common['Authorization']) {
      axios.defaults.headers.common['Authorization'] = getCookie('token');
    } else if (options && options.Authorization) {
      delete axios.defaults.headers.common['Authorization'];
    }

    return new Promise((resolve, reject) => {
      axios.get(
        url + qs.stringify(data, { arrayFormat: 'repeat', addQueryPrefix: true })
      ).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  },
  post: (url, data, options) => {
    let _data = qs.stringify(data);

    if (options && options.headers && options.headers['Content-Type']) {
      axios.defaults.headers.post['Content-Type'] = options.headers['Content-Type'];

      if (options.headers['Content-Type'] == 'application/json;charset=UTF-8') {
        _data = JSON.stringify(data);
      }
    }

    if (getCookie('token') && !axios.defaults.headers.common['Authorization']) {
      axios.defaults.headers.common['Authorization'] = getCookie('token');
    } else if (options && options.Authorization) {
      delete axios.defaults.headers.common['Authorization'];
    }

    return new Promise((resolve, reject) => {
      axios.post(url, _data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  },
  put: (url, data, options) => {
    let _data = qs.stringify(data);

    if (options && options.headers && options.headers['Content-Type']) {
      axios.defaults.headers.post['Content-Type'] = options.headers['Content-Type'];

      if (options.headers['Content-Type'] == 'application/json;charset=UTF-8') {
        _data = JSON.stringify(data);
      }
    }

    if (getCookie('token') && !axios.defaults.headers.common['Authorization']) {
      axios.defaults.headers.common['Authorization'] = getCookie('token');
    } else if (options && options.Authorization) {
      delete axios.defaults.headers.common['Authorization'];
    }

    return new Promise((resolve, reject) => {
      axios.put(url, _data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  }
}

export default http