<template>
  <router-view />
</template>

<script>
import { setCookie, getCookie } from "@/utils/util.js";

export default {
  data() {
    return {
      corpsecret: "",
      nonceStr: ""
    };
  },
  mounted() {
    if (window.name && window.name * 1 > 0) {
      let timer = setInterval(() => {
        let time = window.name;
        time--;
        window.name = time;

        if (time < 60 && time >= 0) {
          clearInterval(timer);
          this.rerefsh();
        }
      }, 1000);
    }
  },
  methods: {
    rerefsh() {
      this.$request.authRefresh(
        {
          token: getCookie("token")
        },
        response => {
          if (response.status === 200) {
            const result = response.data;
            setCookie("token", result.token, 20 * 60 * 1000);
            sessionStorage.token = result.token;
            window.name = 20 * 60;
            // this.handleRerefshTime(20 * 60);
            sessionStorage.currentUser = JSON.stringify(result);
          }
        }
      );
    },
  }
};
</script>


<style lang="scss">
@import "@/styles/base.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
