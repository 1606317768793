import CryptoJS from 'crypto-js'
import EXIF from "exif-js";
import * as qiniu from "qiniu-js";
import { Toast, Notify } from "vant";

const UA = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false;
}

const setCookie = (name, value, time) => {
  var d = new Date();
  var expires = "expires=" + new Date(d.getTime() + time);
  document.cookie = `${name}=${value}; ${expires};`;
}

const getCookie = (name) => {
  var n = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(n) == 0) { return c.substring(n.length, c.length); }
  }
  return "";
}

const getQueryArgs = arg => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == arg) { return pair[1]; }
  }
  return (false);
}

const decrypt = (word) => {
  var key = CryptoJS.enc.Base64.parse(word);
  return key.toString(CryptoJS.enc.Utf8);
}

const exif = (file) => {
  return new Promise((resolve) => {
    EXIF.getData(file, function () {
      const info = EXIF.getAllTags(this);
      resolve(info);
    });
  });
}

const dataURLtoFile = (dataUrl, name) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name, { type: mime });
}

const renderImage = (img, orientation, fn) => {
  const canvas = document.createElement('canvas');

  //获取图片的高宽
  var w = img.width;
  var h = img.height;
  //角度转为弧度
  let rot;
  let iosSys = navigator.userAgent.match('OS 14_');
  switch (orientation) {
    case 1:
      rot = 0;
      break;
    case 6:
      // ios14 旋转 0  
      // ios12 rot = 90;
      rot = iosSys ? 0 : 90;
      break;
    case 3:
      // ios14 旋转 90
      // ios12 
      rot = 180;
      break;
    case 8:
      // ios14 旋转 180
      // ios12 rot = 270;
      rot = iosSys ? 180 : 270;
      break;
    default:
      rot = 0;
      break;
  }

  var rotation = Math.PI * rot / 180;
  var c = Math.round(Math.cos(rotation) * 1000) / 1000;
  var s = Math.round(Math.sin(rotation) * 1000) / 1000;
  //旋转后canvas标签的大小
  canvas.height = Math.abs(c * h) + Math.abs(s * w);
  canvas.width = Math.abs(c * w) + Math.abs(s * h);
  //绘图开始
  var context = canvas.getContext("2d");
  context.save();
  //改变中心点
  if (rotation <= Math.PI / 2) {
    context.translate(s * h, 0);
  } else if (rotation <= Math.PI) {
    context.translate(canvas.width, -c * h);
  } else if (rotation <= 1.5 * Math.PI) {
    context.translate(-c * w, canvas.height);
  } else {
    context.translate(0, -s * w);
  }
  //旋转90°
  context.rotate(rotation);
  //绘制
  context.drawImage(img, 0, 0, w, h);
  context.restore();

  let base64 = canvas.toDataURL('image/jpeg', 0.8);
  fn && fn(base64);
}

const compressImage = (file, options, callback) => {
  qiniu.compressImage(file, options).then(res => {
    callback && callback(res);
  });
}

const qiniuUpload = (file, path, token, callback) => {
  var observable = qiniu.upload(new Blob([file]), path, token);
  observable.subscribe(
    res => {
      console.log("这里用于判断图片是否上传完成");
    },
    err => {
      Notify({ type: "warning", message: "上传失败" });
      Toast.clear();
    },
    res => {
      Toast.clear();
      Notify({ type: "success", message: "上传成功" });

      callback && callback(res);
    }
  );
}


export {
  UA,
  setCookie,
  getCookie,
  getQueryArgs,
  decrypt,
  exif,
  dataURLtoFile,
  renderImage,
  compressImage,
  qiniuUpload,
}