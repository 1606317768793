import { createApp } from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible'
import App from './App.vue'
import router from './router'
import store from './store'

import { api } from './utils/api'
import request from './utils/request'

const app = createApp(App)
app.config.globalProperties.$api = api
app.config.globalProperties.$request = request

app.use(store).use(router).use(Vant).mount('#app')
