<template>
  <div class="form-picker-list">
    <van-cell v-if="search">
      <van-field
        v-model="py"
        placeholder="请输入"
      />
    </van-cell>
    <van-cell>
      <div class="form-picker-list-title">{{title}}</div>
      <van-row gutter="10">
        <van-col
          :span="24/(column || 3)"
          :key="item.id"
          v-for="item in columnsData"
          @click="handleColumn(item)"
        >
          <div
            class="name"
            :class="{'active': active == item.id}"
          >{{item.name}}</div>
        </van-col>
        <van-col v-if="columnsData.length%(column || 3) != 0"></van-col>
      </van-row>
    </van-cell>
  </div>
</template>

<script>
export default {
  props: ["columns", "column", "title", 'activeId', 'step', 'search'],
  data() {
    return {
      active: 10000,
      columnsData: [],
      defaultColumns: [],
      py: '',
    }
  },
  mounted() {
    const { $watch, columns, step } = this;
    this.defaultColumns = JSON.parse(JSON.stringify(columns));

    if (step) {
      const timeChunk = (ary, fn, count) => {
        let start = () => {
          for (let i = 0; i < Math.min(count || 1, ary.length); i++) {
            fn(ary.shift());
          }
        }

        return function() {
          let t = setInterval(() => {
            if (ary.length === 0) {
              return clearInterval(t);
            }
            start();
          }, 200);
        }
      }
      const initColumns = () => {
        let ary = [];
        let renderDriver = timeChunk(columns, item => {
          ary.push(item);
        }, 20)
        renderDriver();

        this.columnsData = ary;
      }
      initColumns();
    }else{
      this.columnsData = columns;
    }

    $watch('py', newValue => {
      this.py = newValue;
      const { defaultColumns } = this;
      let columnsData = defaultColumns.filter(o => o.name.match(newValue));
      this.columnsData = newValue ? columnsData : [];
    })
 
    $watch('activeId', newValue => this.active = newValue)
    $watch('columns', newValue => this.columnsData = newValue)
  },
  methods: {
    handleColumn(item) {
      this.active = item.id;
      this.$emit("change", { ...item });
    }
  }
};
</script>


<style lang="scss">
.form-picker-list {
  max-height: 300px;
  overflow-y: auto;

  .form-picker-list-title {
    margin-bottom: 10px;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #ebedf0;
    padding: 5px 10px;
    margin-bottom: 10px;

    &.active {
      color: #1989fa;
      border-color: #1989fa;
    }
  }
}
</style>
