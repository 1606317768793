<template>
  <div class="work">
    <div class="upload-info">
      <div class="title">添加车头照片</div>
      <Upload v-on:onupload="handleSend" />
      <div class="tips">
        <p class="error" v-if="externalVisible">
          <span>该车非我司车辆</span>
          <span>请选择外包公司</span>
        </p>
        <p class="success" v-if="companyVisible"><span>该车为我司车辆</span></p>
        <p class="error" v-if="visible">
          <span>无法识别车辆信息</span>
          <span>请重新拍照并上传</span>
        </p>
      </div>
    </div>

    <Form
      :data="data"
      :columnsDumpSite="columnsProjectDump"
      :columnsConstructionSection="columnsConstructionSection"
      :columnsCompany="columnsCompany"
      :visible="externalVisible"
      v-on:handleVehicle="handleVehicle"
      v-on:add="handleAdd"
      v-on:onsubmit="onSubmit"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { Toast, Notify } from 'vant';
import Upload from "@/components/Upload.vue";
import Form from "./components/Form.vue";

export default {
  name: 'Work',
  props: [
    'projectActive',
    'columnsProjectDump',
  ],
  components: { Upload, Form },
  data() {
    return {
      data: {},
      params: {},
      externalVisible: false,
      companyVisible: false,
      visible: false,
      columnsConstructionSection: [],
      columnsCompany: [],
    };
  },
  mounted() {
    const { $watch, projectActive } = this;

    $watch('projectActive', newValue => {
      this.data = {};
      this.companyVisible = false;
      this.externalVisible = false;
      this.visible = false;
      this.loadConstructionSection({ projectId: newValue.projectId });
    })
    this.loadConstructionSection({ projectId: projectActive.projectId });
  },
  methods: {
    handleSend({ url, lng, lat }) {
      this.params = {
        startImg: url,
        startLongitude: lng,
        startLatitude: lat,
      }
      
      Toast.loading({
        message: '识别中...',
        forbidClick: true,
        duration: 0,
      });

      this.$request.updateImg({
        url,
        type: 2
      }, response => {
        if (response.status === 200) {
          Toast.clear();
          const result = response.data;
          if (result.retCode === 200) {
            const { license, alias } = result;
            this.data = { license, alias };
            this.visible = false;

            this.loadVehicle({ license });
          } else {
            this.data = {};
            this.params = {};
            this.visible = true;
          }
        }
      }, {
        Authorization: true
      })
    },
    handleVehicle({ license, alias }) {
      this.loadVehicle({ license, alias });
    },
    loadVehicle({ license, alias }) {
      const initVehicle = result => {
        if (result) {
          const { id, license, alias } = result;
          this.data = { license, alias };
          this.companyVisible = true;
          this.externalVisible = false;

          this.$request.queryDriver({ id: result.id }, response => {
            if (response.status == 200) {
              if (response.data.driver) {
                const { id, driverName, userId } = response.data.driver;
                this.params.driverId = id;
                this.params.driverName = driverName;
                this.params.driverUserId = userId;
                this.data = { ...this.data, driverName };
              }
            }
          });
        } else {
          this.data = { license, alias };
          this.params.driverId = '';
          this.params.driverName = '';
          this.params.driverUserId = '';
          this.companyVisible = false;
          this.externalVisible = true;
          this.loadCompany();
        }
      }

      if (!sessionStorage.vehicle) {
        this.$request.queryVehicle({}, response => {
          if (response.status === 200) {
            const result = response.data;
            sessionStorage.vehicle = JSON.stringify(result);

            let data = result.filter(item => item.license == license || item.alias == alias);
            initVehicle(data[0]);
          }
        })
      }else{
        const result = JSON.parse(sessionStorage.getItem('vehicle')) || [];
        let data = result.filter(item => item.license == license || item.alias == alias);
        initVehicle(data[0]);
      }
    },
    loadConstructionSection(params) {
      this.$request.queryConstructionSection({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          result.map(item => {
            item.text = item.name;
          })
          this.columnsConstructionSection = result;
        }
      })
    },
    handleAdd({ name }) {
      const { projectActive: { projectId } } = this;
      this.$request.putConstructionSection({ projectId, name }, response => {
        if (response.status === 200) {
          const result = response.data;
          
           if (result.code === 1) {
            Notify({ type: 'success', message: '施工段添加成功' });
            
            let item = { id: result.data, text: name, name };
            this.columnsConstructionSection.push(item);
            localStorage.removeItem('columnActiveConstruction');
          }
        }
      })
    },
    loadCompany() {
      this.$request.queryExternalCompany({}, response => {
        if (response.status === 200) {
          const result = response.data;
          result.map(item => {
            item.text = item.name;
          })
          this.columnsCompany = result;
        }
      })
    },
    onSubmit(values) {
      const { $store, params, projectActive } = this;
      values = { ...values, ...params, ...projectActive };

      if (!values.startImg) {
        Toast('图片不能为空');
        return;
      }
      
      if (!values.license && !values.alias) {
        Toast('车牌号、大号需选填一项');
        return;
      }

      _.forIn(values, (v, k) => {
        if (!v) delete values[k];
      })

      if ($store.state.loading) return;
      $store.commit('setLoading', { loading: true });

      this.$request.addWaybill({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit('setLoading', { loading: false });
          
          if (result.code === 1) {
            Notify({ type: 'success', message: '保存成功' });
            this.data = {};
            this.params = {};
            this.$router.replace({ path: '/complate', query: { source: 'work' } });
          }else{
            Notify({ type: 'warning', message: result.msg });
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.work {
  .upload-info {
    background: #fff;
    padding: 12px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
    }

    .tips {
      width: 50%;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      float: right;

      .error {
        color: $red;
      }

      .success {
        color: $blue;
      }

      span {
        display: block;
        font-size: 16px;
      }
    }
  }

  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $borderColor;
    text-align: center;
    padding: 3px;
  }
}
</style>
