<template>
  <van-dropdown-menu>
    <van-dropdown-item :title="`当前工地${title}`" ref="project">
      <van-tabs v-model:active="active" @click="onChange">
        <van-tab title="装点">
          <Picker
            :columns="columnsProjectWork"
            :activeId="activeId"
            v-on:change="value => columnActiveProject = value"
          />
        </van-tab>
        <van-tab title="卸点">
          <Picker
            :columns="columnsProjectDump"
            :activeId="activeId"
            v-on:change="value => columnActiveProject = value"
          />
        </van-tab>
      </van-tabs>

      <div style="padding: 5px 16px;">
        <van-button type="primary" block round :disabled="disabled" @click="onConfirm">
          确认
        </van-button>
      </div>
    </van-dropdown-item>
  </van-dropdown-menu>
</template>


<script>
import Picker from "../../../components/Picker.vue";

export default {
  props: [
    'columnsProjectWork',
    'columnsProjectDump',
  ],
  components: { Picker },
  data() {
    return {
      active: 0,
      title: '',
      activeId: 100000,
      columnActiveProject: {},
      disabled: true,
    };
  },
  mounted() {
    this.columnActiveProject = JSON.parse(localStorage.getItem('columnActiveProject')) || {};
    this.tabActive = JSON.parse(localStorage.getItem('tabActive')) || {};
    this.active = this.tabActive.active;
    this.activeId = this.columnActiveProject.id;

    if (this.tabActive && this.tabActive.title) {
      this.disabled = false;
      this.title = `：${this.tabActive.title} ${this.columnActiveProject.name}`;
    }

    const { $watch } = this;
    $watch('columnActiveProject', newValue => {
      this.activeId = newValue.id;
      this.disabled = newValue.id ? false : true;
    })
  },
  methods: {
    onChange(name, title) {
      this.columnActiveProject = {};
      this.disabled = true;
      this.activeId = 100000;
    },
    onConfirm() { 
      const { columnActiveProject, active } = this;
      let _title = (active == 0 && '装点') || (active == 1 && '卸点');
      this.title = `：${_title} ${columnActiveProject.name}`;
      localStorage.columnActiveProject = JSON.stringify(columnActiveProject);
      localStorage.tabActive = JSON.stringify({ active: active, title: _title });

      localStorage.removeItem('columnActiveConstruction');

      this.$emit('handleProject', {
        projectId: columnActiveProject.id,
        active,
        dumpPhoto: columnActiveProject.dumpPhoto
      });
      this.$refs.project.toggle();
    },
  },
};
</script>
