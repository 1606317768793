import http from './axios';
import { api, getAry, postAry, putAry } from './api'

const request = {};

getAry.map(item => {
  request[item] = (params, fn, options) => {
    http
      .get(`${api[item]}`, params, options)
      .then(response => {
        fn && fn(response);
      })
  }
})

postAry.map(item => {
  request[item] = (params, fn, options) => {
    http
      .post(`${api[item]}`, params, options)
      .then(response => {
        fn && fn(response);
      })
  }
})

putAry.map(item => {
  request[item] = (params, fn, options) => {
    http
      .put(`${api[item]}`, params, options)
      .then(response => {
        fn && fn(response);
      })
  }
})

export default request